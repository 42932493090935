ion-grid.main-page{
    height: 100%;
    display: grid;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
}

.main-page ion-row {
    justify-content: center;
}

.main-page ion-text {
    text-align: center;
}

.main-page ion-text.title {
    color: white;
    font-size: 44pt
}
.main-page ion-text.subtitle {
    color: gray;
    font-size: 20pt
}

ion-grid.after-container {
    height: 80px;
    display: grid;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    background-color: white;
}

ion-grid.after-container ion-row {
    justify-content: center;
}

ion-grid.after-container ion-text.title {
    color: #1c1c52
}